import { Button } from "flowbite-react";
import { FC, useCallback } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";

const BackButton: FC<{ link?: string }> = ({ link }) => {
  const history = useHistory();
  const handleBack = useCallback(() => {
    if (link) history.push(link);
    else history.goBack();
  }, [history, link]);

  return (
    <Button onClick={handleBack} className=" self-start">
      <IoMdArrowBack className="h-6 w-6 text-black" />
    </Button>
  );
};

export default BackButton;
