import { InputProps } from "../../../../@types";

export const basicInfoFields: InputProps[] = [
  {
    label: "Woningtype*",
    name: "house_type",
    type: "select",
    placeholder: "Selecteer woningtype",
    tooltip: "Selecteer het type woning",
    options: [
      { label: "Eengezinswoning", value: "Eengezinswoning" },
      { label: "Meergezinswoning", value: "Meergezinswoning" },
      { label: "Duplexwoning", value: "Duplexwoning" },
    ],
    required: true,
  },
  {
    label: "Bouwjaar*",
    name: "building_year",
    type: "number",
    placeholder: "2000",
    tooltip: "Voer het jaar in waarin het gebouw is gebouwd",
    required: true,
  },
  {
    label: "Woonoppervlakte (m²)",
    name: "living_area_m2",
    type: "number",
    placeholder: "100",
    tooltip:
      "Voer de totale woonoppervlakte van het pand in vierkante meters in",
  },
  {
    label: "Oppervlakte overige ruimtes (m²)",
    name: "surface_area_other_spaces",
    type: "number",
    placeholder: "20",
    tooltip: "Voer de oppervlakte van overige ruimtes in vierkante meters in",
  },
  {
    label: "Zolderruimte oppervlakte (m²)",
    name: "attic_space_area",
    type: "number",
    placeholder: "15",
    tooltip: "Voer de zolderruimte oppervlakte in vierkante meters in",
  },
  {
    label: "Vaste trap naar zolder",
    name: "fixed_staircase",
    type: "radio",
    options: [
      { label: "Ja", value: "true" },
      { label: "Nee", value: "false" },
    ],
    tooltip: "Geef aan of er een vaste trap naar de zolder is",
  },
  {
    label: "Aantal verwarmde ruimtes",
    name: "no_of_heated_rooms",
    type: "number",
    placeholder: "2",
    tooltip: "Voer het aantal verwarmde ruimtes in",
  },
  {
    label: "Aantal overige ruimtes",
    name: "no_of_other_rooms",
    type: "number",
    placeholder: "0",
    tooltip: "Voer het aantal overige ruimtes in",
  },
  {
    label: "Aantal ruimtes met verwarming als servicekosten",
    name: "no_of_rooms_heat_as_service_cost",
    type: "number",
    placeholder: "0",
    tooltip: "Voer het aantal ruimtes in met verwarming als servicekosten",
  },
  {
    label: "Inwendige oppervlakte (m²)",
    name: "inner_surface_area",
    type: "number",
    placeholder: "100",
    tooltip: "Voer de inwendige oppervlakte in vierkante meters in",
  },
];

export const energyInfoFields: InputProps[] = [
  {
    label: "Energielabel beschikbaar",
    name: "energy_label_available",
    type: "radio",
    options: [
      { label: "Ja", value: "true" },
      { label: "Nee", value: "false" },
    ],
    tooltip: "Geef aan of er een energielabel beschikbaar is voor het pand",
  },
  {
    label: "Energielabel",
    name: "energy_label",
    type: "slider",
    tooltip: "Selecteer het energielabel van het pand",
  },
  {
    label: "Energieprestatievergoeding",
    name: "energy_performance_payment",
    type: "radio",
    options: [
      { label: "Ja", value: "true" },
      { label: "Nee", value: "false" },
    ],
    tooltip: "Geef aan of er een energieprestatievergoeding is",
  },
  {
    label: "Geldigheid energielabel",
    name: "validity",
    type: "select",
    options: [
      { label: "Definitief", value: "definitive" },
      { label: "Verlopen", value: "expired" },
      { label: "Geschat", value: "estimated" },
    ],
    tooltip: "Selecteer de geldigheid van het energielabel",
  },
];

export const kitchenInfoFields: InputProps[] = [
  {
    label: "Aanrechtblad met onderkasten",
    name: "countertop_with_base_cabinets",
    type: "radio",
    options: [
      { label: "Ja", value: "true" },
      { label: "Nee", value: "false" },
    ],
    tooltip: "Geef aan of de keuken een aanrechtblad met onderkasten heeft",
  },
  {
    label: "Lengte aanrechtblad (m)",
    name: "countertop_lenght",
    type: "number",
    placeholder: "2",
    tooltip: "Voer de lengte van het aanrechtblad in meters in",
  },
  {
    label: "Investering voor extra kwaliteit keuken",
    name: "investment_for_kitchen_extra_quality",
    type: "number",
    placeholder: "5000",
    tooltip: "Voer het investeringsbedrag voor extra keuken kwaliteit in",
  },
];

export const sanitaryInfoFields: InputProps[] = [
  {
    label: "Toilet geïnstalleerd in badkamer of douche",
    name: "toilet_installed_in_bathroom_or_shower",
    type: "radio",
    options: [
      { label: "Ja", value: "true" },
      { label: "Nee", value: "false" },
    ],
    tooltip:
      "Geef aan of er een toilet is geïnstalleerd in de badkamer of douche",
  },
  {
    label: "Sanitaire voorzieningen",
    name: "list_sanitary_facilities",
    type: "multiselect",
    options: [
      { label: "Toilet", value: "toilet" },
      { label: "Wastafel", value: "sink" },
      { label: "Bad", value: "bath" },
      { label: "Douche in bad", value: "bath shower" },
      { label: "Douche", value: "shower" },
    ],
    tooltip: "Selecteer alle beschikbare sanitaire voorzieningen",
  },
  {
    label: "Investering voor extra kwaliteit bad",
    name: "investment_for_bath_extra_quality",
    type: "number",
    placeholder: "3000",
    tooltip: "Voer het investeringsbedrag voor extra badkwaliteit in",
  },
  {
    label: "Investering voor extra kwaliteit douche",
    name: "investment_for_shower_extra_quality",
    type: "number",
    placeholder: "2000",
    tooltip: "Voer het investeringsbedrag voor extra douchekwaliteit in",
  },
  {
    label: "Sanitaire voorzieningen aanwezig",
    name: "sanitary_facilities",
    type: "radio",
    options: [
      { label: "Ja", value: "true" },
      { label: "Nee", value: "false" },
    ],
    tooltip: "Geef aan of er sanitaire voorzieningen aanwezig zijn",
  },
  {
    label: "Investering voor extra kwaliteit bad/douche combinatie",
    name: "investment_for_bath_shower_extra_quality",
    type: "number",
    placeholder: "0",
    tooltip:
      "Voer het investeringsbedrag voor extra bad/douche combinatie kwaliteit in",
  },
];

export const additionalInfoFields: InputProps[] = [
  {
    label: "Voorzieningen voor gehandicapten",
    name: "facilities_for_disabled",
    type: "radio",
    options: [
      { label: "Ja", value: "true" },
      { label: "Nee", value: "false" },
    ],
    tooltip: "Geef aan of er voorzieningen zijn voor gehandicapten",
  },
  {
    label: "Kosten besteed aan voorzieningen voor gehandicapten",
    name: "costs_spent_for_facilities_for_disabled",
    type: "number",
    placeholder: "10000",
    tooltip:
      "Voer de kosten in die zijn besteed aan voorzieningen voor gehandicapten",
  },
  {
    label: "Privé buitenruimte (m²)",
    name: "private_outdoor_space_m2",
    type: "number",
    placeholder: "20",
    tooltip: "Voer de grootte van de privé buitenruimte in vierkante meters in",
  },
  {
    label: "Carport",
    name: "carport",
    type: "radio",
    options: [
      { label: "Ja", value: "true" },
      { label: "Nee", value: "false" },
    ],
    tooltip: "Geef aan of er een carport aanwezig is",
  },
  {
    label: "Extra kwaliteit boven 100m²",
    name: "extra_quality_above_100m2",
    type: "radio",
    options: [
      { label: "Ja", value: "true" },
      { label: "Nee", value: "false" },
    ],
    tooltip: "Geef aan of er extra kwaliteit is voor oppervlakte boven 100m²",
  },
  {
    label: "Renovatiekosten",
    name: "renovation_expenses",
    type: "number",
    placeholder: "50000",
    tooltip: "Voer de totale renovatiekosten in",
  },
  {
    label: "Monument",
    name: "monument",
    type: "radio",
    options: [
      { label: "Ja", value: "true" },
      { label: "Nee", value: "false" },
    ],
    tooltip: "Geef aan of het pand een monument is",
  },
  {
    label: "Zorgwoning",
    name: "care_home",
    type: "radio",
    options: [
      { label: "Ja", value: "true" },
      { label: "Nee", value: "false" },
    ],
    tooltip: "Geef aan of het een zorgwoning betreft",
  },
];

export const nonBasicInfoFields: InputProps[] = [
  {
    label: "Area of Rooms (m²)",
    name: "area_of_rooms_m2",
    type: "number",
    placeholder: "100",
    tooltip: "Enter the total area of rooms in square meters",
  },
  {
    label: "Other Surface Area (m²)",
    name: "other_surface_area_m2",
    type: "number",
    placeholder: "20",
    tooltip: "Enter other surface area in square meters",
  },
  {
    label: "Number of Living Spaces",
    name: "number_of_living_spaces",
    type: "number",
    placeholder: "2",
    tooltip: "Enter the number of living spaces",
  },
  // Add more fields as needed
];

export const nonAdditionalInfoFields: InputProps[] = [
  // Add fields for additional information
];

export const progress = [
  "Woningdata",
  "Energielabel",
  "Keuken",
  "Sanitair",
  "Overig",
  "Resultaat",
];
