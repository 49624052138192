import React from "react";
import { Page, Text, View, Image, Svg, Path } from "@react-pdf/renderer";
import styles from "./styles"; // Adjust the import based on your styles location
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";
import convertToEuFormat from "../../../../../helpers/convertToEuFormat";

interface IndividueleMaatregelenPageProps {
  data: any;
}

const MoneyBagIcon = () => (
  <Svg width="15" height="15" viewBox="0 0 24 24">
    <Path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1.41 16.09V20h-2.67v-1.93c-1.71-.36-3.16-1.46-3.27-3.4h1.96c.1 1.05.82 1.87 2.65 1.87 1.96 0 2.4-.98 2.4-1.59 0-.83-.44-1.61-2.67-2.14-2.48-.6-4.18-1.62-4.18-3.67 0-1.72 1.39-2.84 3.11-3.21V4h2.67v1.95c1.86.45 2.79 1.86 2.85 3.39H14.3c-.05-1.11-.64-1.87-2.22-1.87-1.5 0-2.4.68-2.4 1.64 0 .84.65 1.39 2.67 1.91s4.18 1.39 4.18 3.91c-.01 1.83-1.38 2.83-3.12 3.16z"
      fill="#00A651"
    />
  </Svg>
);

const HouseIcon = () => (
  <Svg width="15" height="15" viewBox="0 0 24 24">
    <Path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" fill="#00A651" />
  </Svg>
);

const CashIcon = () => (
  <Svg width="15" height="15" viewBox="0 0 24 24">
    <Path
      d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"
      fill="#00A651"
    />
  </Svg>
);

const IndividueleMaatregelenPage: React.FC<IndividueleMaatregelenPageProps> = ({
  data,
}) => (
  <Page size="A4" style={styles.page}>
    <Text style={styles.pageTitle}>
      Investering richting label {data.label.potential}
    </Text>

    <Text style={styles.pageText}>
      Hier is een hoogtepunt van de eenmalige investering die u mogelijk nodig
      heeft om uw huis duurzamer te maken voor energielabel{" "}
      {data.label.potential}
      en de energie-efficiëntie te garanderen.
    </Text>

    <View style={styles.investmentContainer}>
      <View style={styles.investmentItem}>
        <View style={styles.investmentIconContainer}>
          <MoneyBagIcon />
          <Text style={styles.investmentLabel}>Totaal investering</Text>
        </View>
        <Text style={styles.investmentValue}>
          €{convertToEuFormat(data.financial.total_investment.toFixed(2))}
        </Text>
      </View>

      <View style={styles.investmentItem}>
        <View style={styles.investmentIconContainer}>
          <HouseIcon />
          <Text style={styles.investmentLabel}>Potentieel Ecovalue</Text>
        </View>
        <Text style={styles.investmentValue}>
          €{convertToEuFormat(data.financial.eco_value.toFixed(2))}
        </Text>
      </View>

      <View style={styles.investmentItem}>
        <View style={styles.investmentIconContainer}>
          <CashIcon />
          <Text style={styles.investmentLabel}>Maandelijks besparing</Text>
        </View>
        <Text style={styles.investmentValue}>
          €{convertToEuFormat(data.financial.savings_monthly.toFixed(2))}
        </Text>
      </View>
    </View>

    <View
      style={[styles.labelItem, { position: "absolute", top: 150, left: 318 }]}
    >
      <Text style={styles.labelTitle}>Huidig energielabel</Text>
      <View
        style={[
          styles.labelBox,
          styles[`label${data.label.current}` as keyof typeof styles],
        ]}
      >
        <Text style={styles.labelText}>{data.label.current}</Text>
      </View>
    </View>

    <View
      style={{
        position: "absolute",
        top: 200,
        left: 408,
        width: 107,
        borderBottom: "1px dotted #333",
      }}
    />
    <View
      style={{
        position: "absolute",
        top: 200,
        left: 515,
        width: 1,
        height: 100,
        borderLeft: "1px dotted #333",
      }}
    />

    <View
      style={[styles.labelItem, { position: "absolute", top: 295, left: 450 }]}
    >
      <View
        style={[
          styles.labelBox,
          styles[`label${data.label.potential}` as keyof typeof styles],
        ]}
      >
        <Text style={styles.labelText}>{data.label.potential}</Text>
      </View>
      <Text style={styles.labelTitle}>Potentieel energielabel</Text>
    </View>

    <View style={styles.footer}>
      <Text style={styles.footerText}>Property of Altum AI | © 2024</Text>
      <Image src={altumLogo} style={styles.footerLogo} />
    </View>
  </Page>
);

export default IndividueleMaatregelenPage;
