import {
  RoofInsulation,
  WallInsulation,
  FloorInsulation,
  Shower,
  SolarPanel,
  HeatPump,
  WindowGlass,
  Ventilation,
} from "../../../../../../assets/images/api/sustainability/sustainabilityImages";

export const preparedChangedMeasuresFunc = (data: any) => {
  return () => {
    const measuresArray = Object.entries(data.measures);
    const featuredMeasures = featuredMeasuresFunc(measuresArray);
    return featuredMeasures.filter(
      (measure) => measure[1].before.desc !== measure[1].after.desc,
    );
  };
};

const featuredMeasuresFunc = (measuresArray: [string, unknown][]) => {
  return measuresArray.map((measure: any[]) => {
    const nameInDutch = translateMeasure(measure[0]);
    return [
      measure[0],
      {
        ...measure[1],
        nameInDutch,
      },
    ];
  });
};

export const getMeasureImages = (measure: string) => {
  switch (measure) {
    case "roof_insulation":
      return RoofInsulation;
    case "wall_insulation":
      return WallInsulation;
    case "solar_panels":
      return SolarPanel;
    case "floor_insulation":
      return FloorInsulation;
    case "instalation":
      return HeatPump;
    case "shower":
      return Shower;
    case "living_room_windows":
      return WindowGlass;
    case "bedroom_windows":
      return WindowGlass;
    case "ventilation":
      return Ventilation;
    default:
      return RoofInsulation;
  }
};

export const translateMeasure = (key: string): string => {
  const translations: { [key: string]: string } = {
    floor_insulation: "Vloerisolatie",
    roof_insulation: "Dakisolatie",
    wall_insulation: "Gevelisolatie",
    bedroom_windows: "Glas slaapkamers",
    living_room_windows: "Glas woonkamer",
    solar_panels: "Aantal zonnepanelen",
    instalation: "CV-ketel of warmtepomp",
    shower: "Huidige douche WTW",
    ventilation: "Ventilatie",
  };
  return translations[key] || key;
};
