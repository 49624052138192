import { useState, FormEvent, useEffect } from "react";
import { referenceFields } from "./referencefields";
import FormBody from "../FormBody";
import { ReferenceImage } from "../../../assets/images/api/APIimages";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { postReference } from "../../../redux/actions/referenceActions";
import { useFormContext } from "../../../pages/Api/usage-pages/components/FormContext";

const ReferenceForm = () => {
  const sectionFields = [
    { title: "Adres", startIndex: 0, endIndex: 2 },
    { title: "Huidige situatie", startIndex: 3, endIndex: 24 },
  ];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);

  // const [houseOptions, setHouseOptions] = useState<
  //   FormOptions | FormOptionsSustainability
  // >({
  //   postcode: "",
  //   housenumber: "",
  // });
  const { formValues, setFormValues } = useFormContext();
  const { savedQueries } = useAppSelector((state) => state.reference);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setFormValues({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries, setFormValues]);

  const dispatch = useAppDispatch();

  const handleReferenceFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions = {
      postcode: "",
      housenumber: "",
    };
    formValues.postcode = formValues.postcode.split(" ").join("").toUpperCase();
    Object.entries(formValues).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof FormOptions] = value;
      }
    });
    apiKey && dispatch(postReference({ formData: newFormData, apiKey }));
  };

  return (
    <div>
      <FormBody
        title={"Woningreferenties opvragen"}
        sectionFields={sectionFields}
        desc={
          "Vraag volledig interactief maximaal 30 relevante en vergelijkbare recente Kadaster transacties op voor het doelobject die vóór de opgegeven waarderingsdatum hebben plaatsgevonden."
        }
        img={ReferenceImage}
        path={"https://docs.altum.ai/apis/interactieve-woningreferentie-api"}
        initialFields={referenceFields}
        handleSubmit={handleReferenceFormSubmit}
      />
    </div>
  );
};

export default ReferenceForm;
