import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import PDFReport from "./PDFReport";

const data = {
  address: {
    house_number: 203,
    post_code: "8443DC",
    addition: "C",
    street: "Burgemeester Falkenaweg",
    city: "Heerenveen",
    location: { lat: 52.94789042379725, lon: 5.9383801142748345 },
  },
  building: {
    build_year: 1990,
    inner_surface: 181.0,
    floor_surface: 114.4,
    flat_roof_surface: 19.14,
    pitched_roof_surface: 132.88,
    wall_surface: 158.27,
    glass_living_room_surface: 22.55,
    glass_bedrooms_surface: 18.98,
    type: "vrijstaand",
  },
  label: { current: "A", potential: "A+" },
  provided_label: {
    temporary: "B",
    definitive: "A",
    definitive_type: null,
    definitive_validity: null,
  },
  BENG2: { current: 136, potential: 102 },
  meta: null,
  energyindex: { current: null, potential: null },
  ELG: { current: null, potential: null },
  comfortscore: { current: 4, potential: 4 },
  CO2: { current: 5072, potential: 4384 },
  financial: {
    total_investment: 10858.54,
    total_saving: 1303.45,
    months_to_pay_off: 99,
    eco_value: 1455.0,
    savings_monthly: 109,
    energy_cost_monthly: 323,
    loan: { monthly_payment: 45.0 },
  },
  usage: { gas: 3203, energy: 570, city_heating: 0 },
  usage_potential: { gas: 2066, energy: 1442, city_heating: 0 },
  energy_prices: {
    variable_gas_price: 1.144,
    variable_electricity_price: 0.293,
    solarpanel_watt_peak_per_m2: 212,
  },
  measures: {
    wall_insulation: {
      before: { desc: "Goed", value: 2 },
      after: { desc: "Goed", value: 2 },
      investment: 0.0,
      saving: 0,
      co2_reduce: 0,
      BENG2: "0%",
      gas_savings: 0,
      electricity_savings: 0,
    },
    floor_insulation: {
      before: { desc: "Matig", value: 1 },
      after: { desc: "Matig", value: 1 },
      investment: 0.0,
      saving: 0,
      co2_reduce: 0,
      BENG2: "0%",
      gas_savings: 0,
      electricity_savings: 0,
    },
    roof_insulation: {
      before: { desc: "Goed", value: 2 },
      after: { desc: "Goed", value: 2 },
      investment: 0.0,
      saving: 0,
      co2_reduce: 0,
      BENG2: "0%",
      gas_savings: 0,
      electricity_savings: 0,
    },
    living_room_windows: {
      before: { desc: "Drievoudig glas", value: 3 },
      after: { desc: "Drievoudig glas", value: 3 },
      investment: 0.0,
      saving: 0,
      co2_reduce: 0,
      BENG2: "0%",
      gas_savings: 0,
      electricity_savings: 0,
    },
    bedroom_windows: {
      before: { desc: "Drievoudig glas", value: 3 },
      after: { desc: "Drievoudig glas", value: 3 },
      investment: 0.0,
      saving: 0,
      co2_reduce: 0,
      BENG2: "0%",
      gas_savings: 0,
      electricity_savings: 0,
    },
    ventilation: {
      before: { desc: "Natuurlijk", value: 0 },
      after: { desc: "Natuurlijk", value: 0 },
      investment: 0.0,
      saving: 0,
      co2_reduce: 0,
      BENG2: "0%",
      gas_savings: 0,
      electricity_savings: 0,
    },
    solar_panels: {
      before: { desc: "16", value: 16 },
      after: { desc: "16", value: 16 },
      investment: 0.0,
      saving: 0,
      co2_reduce: 0,
      BENG2: "0%",
      gas_savings: 0,
      electricity_savings: 0,
    },
    instalation: {
      before: { desc: "HR-combi", value: 4 },
      after: { desc: "Hybride warmtepomp", value: 30 },
      investment: 10062.36,
      saving: 870,
      co2_reduce: -38,
      BENG2: "20.7%",
      gas_savings: 1027,
      electricity_savings: -1917,
    },
    shower: {
      before: { desc: "Geen Douche WTW", value: 0 },
      after: { desc: "Douche WTW", value: 1 },
      investment: 796.18,
      saving: 215,
      co2_reduce: 159,
      BENG2: "1.1%",
      gas_savings: 89,
      electricity_savings: 383,
    },
  },
  response_meta: null,
};

const PDFPreview: React.FC<{
  data: any;
  buildingPhoto: string;
  map: string;
}> = ({ data, buildingPhoto, map }) => {
  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <PDFReport
        data={data}
        buildingPhoto={buildingPhoto}
        map={map}
        featuredMeasures={[]}
      />
    </PDFViewer>
  );
};

export default PDFPreview;
