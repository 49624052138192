import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import styles from "./styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";
import { SolarPanel } from "../../../../../assets/images/api/sustainability/sustainabilityImages";

interface CompleteOverzichtPageProps {
  data: any;
}

const CompleteOverzichtPage: React.FC<CompleteOverzichtPageProps> = ({
  data,
}) => (
  <Page size="A4" style={styles.page}>
    <Text style={styles.pageTitle}>Aanbevelingen</Text>

    <Text style={styles.pageText}>
      Op basis van de huidige situatie zijn hier specifieke maatregelen voor een
      lager energieverbruik en een betere duurzaamheid
    </Text>

    <View style={styles.recommendationContainer}>
      <View style={styles.recommendationHeader}>
        <Image src={SolarPanel} style={styles.recommendationIcon} />
        <Text style={styles.recommendationTitle}>Aantal zonnepanelen</Text>
      </View>

      <Text style={styles.recommendationText}>
        Wij raden aan om {data.measures.solar_panels.after.desc} zonnepanelen te
        plaatsen op uw woning. Hieronder vindt u de voordelen:
      </Text>

      <View style={styles.recommendationPoints}>
        <Text style={styles.recommendationPoint}>
          - Significant verlaging van de elektriciteitskosten
        </Text>
        <Text style={styles.recommendationPoint}>
          - Verlaging van de CO2 emissies met{" "}
          {data.measures.solar_panels.co2_reduce} kg per jaar
        </Text>
        <Text style={styles.recommendationPoint}>
          - Maandelijkse besparing van €
          {data.measures.solar_panels.saving.toFixed(2)}
        </Text>
      </View>

      <Text style={styles.installationCost}>
        Installatiekosten: €{data.measures.solar_panels.investment.toFixed(2)}
      </Text>
    </View>

    <View style={styles.footer}>
      <Text style={styles.footerText}>PROPERTY OF ALTUM AI | © 2024</Text>
      <Image src={altumLogo} style={styles.footerLogo} />
    </View>
  </Page>
);

export default CompleteOverzichtPage;
