import React from "react";
import { Page, Text, View, Image, Svg, Path } from "@react-pdf/renderer";
import styles from "./styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";

const LocationIcon = () => (
  <Svg width="12" height="12" viewBox="0 0 24 24">
    <Path
      d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
      fill="#00A651" // Changed to green color
    />
  </Svg>
);

interface BasisgegevensPageProps {
  data: {
    address: {
      street: string;
      house_number: string;
      city: string;
      post_code: string;
    };
    building: {
      build_year: number;
      inner_surface: number;
      type: string;
    };
  };
  buildingPhoto: string;
  map: string;
}

const BasisgegevensPage: React.FC<BasisgegevensPageProps> = ({
  data,
  buildingPhoto,
  map,
}) => (
  <Page size="A4" style={styles.page}>
    <Text style={styles.pageTitle}>Uw vastgoed in één oogopslag</Text>

    <View style={styles.propertyDetailsContainer}>
      <Text style={styles.sectionTitle}>Gegevens woning</Text>
      <View style={styles.detailRow}>
        <Text style={styles.detailLabel}>Volledig adres</Text>
        <Text style={styles.detailValue}>
          {data.address.street} {data.address.house_number}
        </Text>
      </View>
      <View style={styles.detailRow}>
        <Text style={styles.detailLabel}></Text>
        <Text style={styles.detailValue}>{data.address.post_code}</Text>
      </View>
      <View style={styles.detailRow}>
        <Text style={styles.detailLabel}></Text>
        <Text style={styles.detailValue}>{data.address.city}</Text>
      </View>
      <View style={styles.detailRow}>
        <Text style={styles.detailLabel}>Construction year</Text>
        <Text style={styles.detailValue}>{data.building.build_year}</Text>
      </View>
      <View style={styles.detailRow}>
        <Text style={styles.detailLabel}>Living area</Text>
        <Text style={styles.detailValue}>{data.building.inner_surface} m²</Text>
      </View>
      <View style={styles.detailRow}>
        <Text style={styles.detailLabel}>House type</Text>
        <Text style={styles.detailValue}>{data.building.type}</Text>
      </View>
    </View>

    <View style={styles.imageContainer}>
      <View style={styles.imageWrapper}>
        <Image src={buildingPhoto} style={styles.buildingPhoto} />
        <View style={styles.imageCaptionContainer}>
          <LocationIcon />
          <Text style={styles.imageCaption}>
            {data.address.street} {data.address.house_number},{" "}
            {data.address.city}
          </Text>
        </View>
      </View>
    </View>

    <View style={styles.imageContainer}>
      <View style={styles.imageWrapper}>
        <Image src={map} style={styles.mapImage} />
        <View style={styles.imageCaptionContainer}>
          <LocationIcon />
          <Text style={styles.imageCaption}>
            {data.address.street} {data.address.house_number},{" "}
            {data.address.city}
          </Text>
        </View>
      </View>
    </View>

    <View style={styles.footer}>
      <Text style={styles.footerText}>PRODUCT VAN ALTUM AI | © 2024</Text>
      <Image src={altumLogo} style={styles.footerLogo} />
    </View>
  </Page>
);

export default BasisgegevensPage;
